import React from 'react';
import Skeleton from "react-loading-skeleton";
import { Loader } from "common-components-spa";
import { CollapsibleSideBarMenuLoader } from '../collapsible-sidebar-menu/CollapsibleSideBarMenuLoader';
import './exploreLessons.scss';

export default function ExploreLessonsSpaLoader() {

    return (
        <>
            <div className="row">
                <div className="col-3">
                    <CollapsibleSideBarMenuLoader />
                </div>
                <div className="col-9">
                    <div className="mt-3 ogaWrapper-container grid__container">
                        <Loader />
                        {/* {
                            Array(12)
                                .fill()
                                .map((item, index) => (
                                    <div key={index} className={`gsamblk`}>
                                        <div className="gsamblk__txt-wrp d-flex justify-content-between">
                                            <Skeleton height={110} width={97} />
                                            <Skeleton height={16} width={96} />
                                        </div>
                                    </div>
                                ))
                        } */}
                    </div>
                </div>
            </div>
        </>
    )
}