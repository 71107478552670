import React from 'react';
import routes from '../../routes/config';
import { Routes } from '../../components/routes/Routes';

export function App() {
  //TODO: need routing here
  return (
    <Routes routes={routes} defaultRoute={routes[0].path} />
  );
}
