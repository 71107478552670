export const adminLinkConsts = {
    prodHostname: "activehub.pearson.com",
    prodGatewayHostname: "homepage.pearson.com",
    prodGatewaySpaId: "urn:pearson:gps:spa:89c6e5d7-f746-4936-8d61-ea7c2c17ec6a",
    stgHostname: "stg.pet.ift.pearson-intl.com",
    stgGatewayHostname: "stg-gb.ggw.ift.pearson-intl.com",
    stgGatewaySpaId: "urn:pearson:gps:spa:592a83d4-c867-44bd-8313-0cc215fd66b6",
    qaHostname: "qa.pet.ift.pearson-intl.com",
    qaGatewayHostname: "qa-gb.ggw.ift.pearson-intl.com",
    qaGatewaySpaId: "urn:pearson:gps:spa:bdd514e0-479f-4fac-8e54-a591d29ccaf4",
    perfGatewayHostname: "perf-gb.ggw.ift.pearson-intl.com",
    perfGatewaySpaId: "urn:pearson:gps:spa:0935b628-e82a-4e5d-b378-da295db60a22"
}

export const platFormIds = {
    devPlatFormId: "urn:pearson:gps:platform:b2efdfe2-95fe-4d40-9609-6ec21a2b62d3",
    qaPlatFormId: "urn:pearson:gps:platform:cf7aeb0c-8446-4a56-a7bc-a3a61206b5e9",
    stgPlatFormId: "urn:pearson:gps:platform:8dc9ba25-1720-43dc-8fb4-77b87178f585",
    prodPlatFormId: "urn:pearson:gps:platform:7480b48a-6a1e-4307-ab42-9ad0947d65a1",
}

export const isActivehub = true

