import React from "react";
import { useLocation } from 'react-router-dom';
import Skeleton from "react-loading-skeleton";
import "./Header.scss";
import useMediaQuery from '@material-ui/core/useMediaQuery';


/*
  This is temporary fix 
  Detect URL change and close left menu items for mobile device
*/

export function HeaderLoader() {
  const isMobile = !useMediaQuery('(min-width:768px)');
  const { pathname } = useLocation();


  return (
    <div className={`gwHeader`}>
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div className="gwHeaderLogo">
              <Skeleton height={36} width={90} />
            </div>
          </div>
          <div className={`${pathname.includes('/digitalLibrary/list/') || pathname.includes('/alfresco/player/') ? 'd-none' : 'd-flex'}  align-items-center`}>
            <div className="gwSpaWrapper__tabs--ahub">
              {!isMobile &&
                  <Skeleton height={36} width={90} />
              }
              {isMobile &&
                <img src={`${process.env.PUBLIC_URL}/images/Pearson-mobilelogo.svg`} className="d-xl-none" tabIndex="" title="pearson global gateway" alt="pearson global gateway" />
              }
            </div>
          </div>
          <div className="d-flex align-items-center">
            {/* <Can not I="VIEW" a="STUDENT_HOME"> */}
            <div className={`${pathname.includes('/digitalLibrary/list/') || pathname.includes('/alfresco/player/') ? 'd-none' : 'gwnotifi'}`}>
              {!isMobile &&
                <Skeleton height={20} width={20} />
              }
            </div>
            {/* </Can>  */}
            {!isMobile &&
              <div className="gwHelp">
                <Skeleton height={20} width={20} />
              </div>
            }
            <div className="gwProfile">
              <Skeleton height={20} width={20} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
