import React, {useEffect, useMemo} from 'react';
import {createPortal} from 'react-dom';
import PropTypes from 'prop-types';
import './Loader.scss';

export function Loader( props ) {
  const {withWrapper} = props;
  const hostDiv = useMemo(() => {
    let element = document.createElement('div');
    element.classList.add('loader-overlay');
    return element;
  }, []);

  useEffect(() => {
    if (!withWrapper) {
      return;
    }
    document.body.classList.add('overflow-hidden');
    document.body.appendChild(hostDiv);
    return () => {
      document.body.classList.remove('overflow-hidden');
      document.body.removeChild(hostDiv);
    }
  }, [withWrapper]);

  const loading = <div className="gwLoading-spinner gwShow">{'Loading...'}</div>;

  if (!withWrapper) return loading;

  return createPortal(
    <div className="loader-wrapper" aria-live='polite' aria-busy='true'>
      {loading}
    </div>,
    hostDiv
  );
}

Loader.prototype = {
  withWrapper: PropTypes.bool
}

Loader.defaultProps = {
  withWrapper: true
}
