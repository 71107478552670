import moment from "moment";

export const getUuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const getDateISOString = () => {
    return new Date().toISOString();
}

export const getLocalTimestamp = () => {
    return moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS');
}