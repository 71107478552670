import React from 'react';
import './InvalidUserAlert.scss'



export function InvalidUserAlert() {
  return (
    <div class="p-5 m-5 d-flex flex-column justify-content-center align-items-center deactivated-user">
      <div class="d-flex align-item-center justify-content-center accdeactivate">
        <div>
          <img src={`${process.env.PUBLIC_URL}/images/activehub-logo-tablet.svg`} alt="Invalid subscription" />
        </div>
        <div class="rightcnt" >
          <p class="pl-4 header" style={{ color: "#252525", marginBottom: "19px" }}>Account not set up for ActiveHub</p>
          <p class="ml-4 mb-2 subheader">Do you have more than one Pearson account? We recommend the following:</p>
          <p class="ml-4 messages">
            <ul style={{ listStyle: "disc" }}>
              <li>Check if auto-fill has populated the correct ActiveHub username</li>
              <li>View your Pearson accounts by <a target='_blank' href='https://login.pearson.com/v1/piapi/piui/forgot-unpw?client_id=U5AIM36Jb6aNU0MrNG51c9vuQbp0sZ9w&login_success_url=https:%2F%2Factivehub.pearson.com%2F%3FiesCode%3DyAs8mAcKSc&lang=en'>requesting a password reset email</a></li>
              <li>Ensure you are signed out of other Pearson websites</li>
            </ul>
          </p>
          <p className='ml-4 mt-2 button-message'>If you are still experiencing difficulties, please contact our support team by clicking on the ‘Report an issue’ button.</p>
          <p style={{ textAlign: "end" }} class="ml-4 mt-2 text-end "><a class="repotlink" target="_blank" href=" https://www.pearsonschoolsandfecolleges.co.uk/activehub/report-an-issue-on-activehub">Report an issue </a></p>
        </div>

      </div>
    </div >
  )
}
