import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../App';
import {
  getUserProfile, getUserStatus, getAppStatus
} from '../../network/ApiService';
import { Home } from '../../page';
import { Loader } from '../../components/loader/Loader'
import ExploreLessonsSpaLoader from '../../components/explore-lessons/ExploreLessonsSpaLoader';
import ExploreLessonsLoader from '../../components/explore-lessons/ExploreLessonsLoader';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { useNetworkResponse } from 'common-components-spa';
import { PMRs } from '../../constants/Config';
import { DeactivatedUserAlert } from '../../components/deactivated-user-alert/DeactivatedUserAlert';
import { InvalidUserAlert } from '../../components/deactivated-user-alert/InvalidUserAlert';
import Maintenance from '../../components/Maintenance/Maintenance';


const USERSTATUSCONST = {
  "ACTIVE": "ACTIVE",
  "DISABLED": "DEACTIVATED"
}

export function Callback() {
  const [loadingDone, setLoadingDone] = useState(false);

  const appContext = useContext(AppContext);

  const {
    headerDetails,
    iesSession,
    setUserName,
    userName,
    wrapperLevelErrorPage,
    setWrapperLevelErrorPage
  } = appContext;

  const userStatusResponse = useNetworkResponse();

  const fetchUserStatus = (pmrIds) => {
    userStatusResponse.dispatch({
      type: 'STARTED'
    })
    const subs = getUserStatus(pmrIds).subscribe(res => {
      userStatusResponse.dispatch({
        type: 'SUCCESS',
        payload: res
      })
      if(res === USERSTATUSCONST.DISABLED) {
        setWrapperLevelErrorPage(<DeactivatedUserAlert />);
      }
    },
    err => {
      userStatusResponse.dispatch({
        type: 'ERROR',
        payload: err
      })
      setWrapperLevelErrorPage(<InvalidUserAlert />);
      console.log("Error in status fetch", err)
    })
    return subs;
  }

  const fetchAppStatus = () => {
    const status = getAppStatus().subscribe(res => {
      console.log("App Status >> ", res)
      if(res == true) {
        setWrapperLevelErrorPage(<Maintenance />);
      }
    },
    err => {
      console.log("Error in status fetch", err)
    })
    return status;
  }


  useEffect(() => {
    const appStatus = fetchAppStatus();
    const usrStatsSubs = fetchUserStatus(PMRs);
        const subscription = getUserProfile(iesSession.getUserId())
      .subscribe(
        response => {
          if (!response) {
            console.log('error in api', 'no data in user api');
            return;
          }

          const givenName = response.firstName;
          const familyName = response.lastName;
          let firstInitial = givenName && givenName.length > 0 &&
            givenName[0].toUpperCase();
          let familyInitial = familyName && familyName.length > 0 &&
            familyName[0].toUpperCase();
          setUserName(
            `${firstInitial || ''}${familyInitial || ''}`,
          );
          window.USERPROFILE = { ...response }
        },
        error => {
          if (error && error.data && error.data.status >= 500) {
            setUserName('-')
          } else if (error && error.data && error.data.status === 401) {
            iesSession.logout();
          } else if (error && error.data && error.data.status === 404) {
            setUserName('-')
          }
          setUserName('-')
          console.log('error in api', error)
        },
      );

    return () => {
      appStatus.unsubscribe();
      subscription.unsubscribe();
      usrStatsSubs.unsubscribe();
    };
    // dont need to run this effect when setters change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('Loading done', headerDetails, userName, wrapperLevelErrorPage);
    if (userName || wrapperLevelErrorPage) {
      setLoadingDone(true);
    } else {
      setLoadingDone(false);
    }
  }, [userName, wrapperLevelErrorPage]);

  if (!loadingDone) {
    return (
      <ExploreLessonsLoader />
    );
  }
  return <Home />;
}