import React from 'react';
import PreLogin from './components/pre-login/PreLogin';
import App from './App';
import { Route, Switch } from "react-router-dom";

export default function AppWrapper() {
    return (
        <>
            <Switch>
                <Route exact path="/login">
                    <PreLogin />
                </Route>
                <Route path="/">
                    <App />
                </Route>
            </Switch>
        </>
    )

}