import React, { useEffect, useContext, useState } from 'react';
import { Button, useNetworkResponse } from 'common-components-spa';
import { RadioButton, Loader } from 'common-components-spa';
import moment from 'moment';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import { AppContext } from '../../App';
import { getPreviewLiveStatus, makeCourseLive } from '../../network/ApiService';
import { UnAuthorizedUserAlert } from '../../components/unauthorized-user-alert/UnAuthorizedUserAlert';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#007FA3',
    color: theme.palette.common.white,
    fontSize: 14,
    textAlign: 'center',
    fontWeight: 'bold',

  },
  body: {
    fontSize: 14,

  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  liveBtn: {
    background: '#007FA3',
    boxShadow: '0px 0px 17.6087px #D9DFE7',
    borderRadius: '22.2609px',
    width: '120px',
    height: '40px',
  },
  btnText: {
    color: 'white'
  },
  emptyRow: {
    textAlign: 'center'
  }
});

const COURSESTATUS = {
  LIVE: 'LIVE',
  PREVIEW: 'PREVIEW',
  LIVE_REQUESTED: 'LIVE REQUESTED'
}

export default function StatusWrapper() {
  const appContext = useContext(AppContext);
  const { organization, setConfirmPopupState, isPearsonAdmin } = appContext;
  const classes = useStyles();

  const [courseStatus, setCourseStatus] = useState([]);
  const [isPPIDChecked, setPPIDChecked] = useState('product-id');
  const [isLoading, setIsLoading] = useState(false);

  const courseStatusResponse = useNetworkResponse();
  const makeCourseLiveResponse = useNetworkResponse();

  useEffect(() => {
    if (!organization || !organization.length || !isPearsonAdmin) {
      return false;
    }
    const isPPID = isPPIDChecked === 'product-id' ? true : false;
    getCoursesLivePreview(isPPID);
  }, [organization]);

  const getCoursesLivePreview = (isPPID) => {
    courseStatusResponse.dispatch({ type: 'STARTED' });
    const sub = getPreviewLiveStatus(organization[0].productModelRegions[0], organization[0].organizationId, isPPID).subscribe(
      res => {
        courseStatusResponse.dispatch({ type: 'SUCCESS', payload: res });
        setCourseStatus(res['content']);
      },
      err => {
        courseStatusResponse.dispatch({ type: 'ERROR', payload: err });
        console.log(err);
      }
    )

    return () => sub.unsubscribe();
  }

  const requestToMakeCourseLive = (data) => {
    makeCourseLiveResponse.dispatch({ type: 'STARTED' });
    const payload = {
      id: data.id,
      liveRequestMadeBy: window?.USERPROFILE.email,
      customerResourceId: data?.customerVersion?.resourceId,
      latestPublishedResourceId: data?.latestPublishedVersion?.resourceId,
      courseName: data.courseName,
      resourceEntityId: data.resourceEntityId
    }
    const sub = makeCourseLive(organization[0].productModelRegions[0], organization[0].organizationId, { payload }).subscribe(
      res => {
        makeCourseLiveResponse.dispatch({ type: 'SUCCESS', payload: res });
        const courses = courseStatus.map(c => c.id === res.id ? res : c);
        setCourseStatus(courses);
      },
      err => {
        makeCourseLiveResponse.dispatch({ type: 'ERROR', payload: err });
        console.log(err);
      }
    )

    return () => sub.unsubscribe();
  }

  const showConfirmationPopup = (data) => {
    setConfirmPopupState({
      isModalshow: true,
      title: "",
      dialogMsg:
        `Are you sure that you want to publish version ${data.latestPublishedVersion?.version} of ${data.courseName} to subscribing schools? If yes, this version will be made available to schools on ${moment().add(1, 'days').format('LL')}`,
      positiveButtonText: "Yes",
      positiveButtonHandle: () => {
        setConfirmPopupState({ isModalshow: false });
        requestToMakeCourseLive(data)
      },
      negativeButtonText: "Cancel",
      negativeButtonHandle: () => {
        setConfirmPopupState({ isModalshow: false });
      },
      positiveButtonStyleClass: "",
      negativeButtonStyleClass: "",
      subInfo: "",
    });
  }

  const onChange = (e) => {
    console.log(e.target.id);
    setPPIDChecked(e.target.id);
    const isPPID = e.target.id === 'product-id' ? true : false;
    getCoursesLivePreview(isPPID);
  }

  return (
    <>
      {!isPearsonAdmin && <UnAuthorizedUserAlert />}
      {isPearsonAdmin &&
        <div style={{ marginTop: "2%" }} className="ogaWrapper__bdy active-hub__password mx-1" >
          {(courseStatusResponse.isLoading || makeCourseLiveResponse.isLoading) && <Loader />}
          <div className='d-flex'>
            <span className='mr-3'>
              <RadioButton
                data={{ id: 'product-id', name: 'PPID course' }}
                onChange={onChange}
                checked={
                  'product-id' === isPPIDChecked
                }
              />
            </span>
            <span>
              <RadioButton
                data={{ id: 'non-product-id', name: 'Non-PPID course' }}
                onChange={onChange}
                checked={
                  'non-product-id' === isPPIDChecked
                }
              />
            </span>

          </div>
          <TableContainer style={{ outline: '2px solid #007FA3', borderRadius: '10px' }}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Entity ID</StyledTableCell>
                  <StyledTableCell>Course name</StyledTableCell>
                  <StyledTableCell>Customer version</StyledTableCell>
                  <StyledTableCell>Customer live date</StyledTableCell>
                  <StyledTableCell>Latest published version</StyledTableCell>
                  <StyledTableCell>Date published</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Publish to Live</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {courseStatus.map((row) => (
                  <StyledTableRow key={row.resourceEntityId}>
                    <StyledTableCell component="th" scope="row">{row.resourceEntityId} </StyledTableCell>
                    <StyledTableCell align="center"><b>{row.courseName}</b></StyledTableCell>
                    <Tooltip title={row?.customerVersion?.resourceId}><StyledTableCell width={'10%'} align="center">{row?.customerVersion?.version}</StyledTableCell></Tooltip>
                    <StyledTableCell width={'12%'} align="center">{moment(row?.customerVersion?.publishedDate).format('LL')}</StyledTableCell>
                    <Tooltip title={row?.latestPublishedVersion?.resourceId}><StyledTableCell width={'12%'} align="center">{row?.latestPublishedVersion?.version}</StyledTableCell></Tooltip>
                    <StyledTableCell align="center">{moment(row?.latestPublishedVersion?.publishedDate).format('LL')}</StyledTableCell>
                    <StyledTableCell align="center"><b>{COURSESTATUS[row?.publishingStatus]}</b></StyledTableCell>
                    <StyledTableCell align="center">
                      {
                        row?.publishingStatus === COURSESTATUS.PREVIEW &&
                        <Button
                          useButtonClass={false}
                          ariaDescribedby={"sign in"}
                          onClick={() => showConfirmationPopup(row)}
                          id="ALSecondary"
                          className={classes.liveBtn} >
                          <span className={classes.btnText}>Publish to live</span>
                        </Button>
                      }
                    </StyledTableCell>

                  </StyledTableRow>
                ))}
                {!courseStatus.length &&
                  <StyledTableRow>
                    <StyledTableCell colSpan={8} className={classes.emptyRow}>
                      No courses found
                    </StyledTableCell>
                  </StyledTableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      }
    </>
  );
}