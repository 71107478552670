import React from 'react';
import { Button } from 'common-components-spa';
import { Footer } from '../footer/Footer';

const PreLoginMobile = ({ footerLinks, setClient, isMobile }) => {
    return (
        <div className='prelogin-mobile'>
            <div className="activehub-logo-container">
                <img
                    src={`${process.env.PUBLIC_URL}/images/activehub-logo-tablet.svg`}
                    alt="logo"
                    className="activehub-logo tablet"
                />
                <img
                    src={`${process.env.PUBLIC_URL}/images/activehub-logo-mobile.svg`}
                    alt="logo"
                    className="activehub-logo mobile"
                />
            </div>
            <div className="activehub-text">
                <div className="creation__logo">
                    ActiveHub
                </div>
                <div className="creation__insights">
                    Powered by Insight.
                </div>
                <div className="creation__insights__guided">
                    Guided by you.
                </div>
            </div>
            <div className='signin-container'>
                <div className="w-auto center-items">
                    <Button
                        useButtonClass={false}
                        ariaDescribedby={"sign in"}
                        onClick={setClient}
                        id="ALSecondary"
                        className={`sign-in`} >
                        <span className='sign-in-text'>Sign in</span>
                    </Button>
                </div>
                <div className="links-container center-items">
                    <span className='links mr-link'><a rel='noreferrer' target="_blank" href='https://www.pearsonschoolsandfecolleges.co.uk/activehub'>Find out more</a></span>
                    <span className='links'><a rel='noreferrer' href='https://support.pearson.com/uk/s/digital-contactus' target="_blank">Contact us</a></span>
                </div>
                {/* {isMobile && <div className="grey-line"></div>}
                <div className='p-2 mr-2 mt-5'>
                    <div className='updates mb-3'>
                        Latest Updates
                    </div>
                    <div className="gsamblk">
                        <div className="gsamblk__txt-wrp">
                            <div className='update-heading'>
                                New Science online activities
                            </div>
                            <div className='gsamblk__txt-wrp_txt'>
                                You can now assign our new combined science activities with more interactive questions and examples that will help your students understand blah blah blah blah blah blah blah.
                            </div>
                            <div className='learn-more'>
                                <span className='mr-2'>New Science online activities</span>
                                <span>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/learn-more.svg`}
                                        alt="logo"
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <div role="contentinfo" className="gwWraper_footer">
                <Footer footerLinks={footerLinks || []} />
            </div>
        </div>
    )
}

export default PreLoginMobile;
