import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import MobileMenu from './MobileMenu';
import SpaMenu from './SpaMenu';
import './Menu.scss';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawerPaperMobile: {
        width: drawerWidth
    },
    drawerPaperDesktop: {
        width: drawerWidth,
        marginTop: "65px",
        bottom: "0px",
        zIndex: 1,
        border: 'none',
    }
}));

export default function ResponsiveNavigationMenu(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {props.isMobile &&
                <Drawer
                    anchor={"left"}
                    open={props.mobileOpen}
                    onClose={props.handleDrawerToggle}
                    classes={{
                        paper: classes.drawerPaperMobile,
                    }}
                >
                    <MobileMenu activePath={props.activePath} setMobileOpen={props.setMobileOpen} setSelectedSpaId={props.setSelectedSpaId} spaRoutes={props.spaRoutes} onClose={props.handleDrawerToggle} selectedSpaId={props.selectedSpaId} navItems={props.navItems} adminLinkClickHandler={props.adminLinkClickHandler}/>
                </Drawer>}

            {props.showMenu && props.selectedSpaRoutes.length !== 0 && !props.isMobile &&
                <Drawer
                    variant="permanent"
                    open
                    classes={{
                        paper: classes.drawerPaperDesktop,
                    }}
                >
                    <SpaMenu selectedSpaRoutes={props.selectedSpaRoutes} selectedSpaId={props.selectedSpaId} t={props.t} activePath={props.activePath} setActivePath={props.setActivePath} />
                </Drawer>
            }
        </div>
    );
}
