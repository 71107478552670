//mock data for userDetails
export const userInitials = "GS";

//header image
export const headerImage = `${process.env.PUBLIC_URL}/images/Pearson-activehub-logo.png`;

/**header help link consts */
const CATEGORY_BLOG = "c11deabb-ca3d-4bcd-a8f8-1ad066b40f64";
export const OLD_HELP_PAGE_BASE_URL = "https://www.pearsonschoolsandfecolleges.co.uk/help/helphub";
export const TEACHER_HELP_PAGE_BASE_URL = `https://www.pearsonschoolsandfecolleges.co.uk/help/helphub?categoryBlog=${CATEGORY_BLOG}`
export const STUDENT_HELP_PAGE_BASE_URL = `https://www.pearsonschoolsandfecolleges.co.uk/help/helphub/students`;

export const INSIGHTS_SPA_ID = ["urn:pearson:gps:spa:259dd785-38b6-4120-8c68-666d70cdf038", 
                                "urn:pearson:gps:spa:6c8148f4-ac0f-4b93-a8a7-a73733649f54",
                                "urn:pearson:gps:spa:0f63bf09-ae5f-4136-bd99-b95d469e4599"];

export const ASSESSMENTS_SPA_ID = ["urn:pearson:gps:spa:4a3b2922-518b-4a6d-b18e-45f4b1b047dd", 
                                    "urn:pearson:gps:spa:834207a7-72ba-44c0-90ed-52bc128758c7", 
                                    "urn:pearson:gps:spa:d051717f-cb63-4563-969c-da8032901aeb"];

export const LESSON_PLAN_SPA_ID = ["urn:pearson:gps:spa:5d119191-d931-44c6-8d0a-d167c9b206e3", 
                                    "urn:pearson:gps:spa:7dcf997a-5233-4c8e-b29c-63f998d38201", 
                                    "urn:pearson:gps:spa:a5c4b418-cfcd-46b7-a488-bb9a433421f5"];

export const DASHBOARD_ID = "dashboard";
export const INSIGHTS_STUDENT_SHARE_KEY = "studentShare";
/**maps for diffent spas */
export const LESSON_PLAN_HELP_SECTION_MAP = {
    "courseinstance" : "findingresources",
    "toc": "findingresources", //review
    "list": "tasksresults",
    "detail": "tasksresults",
    "report": "tasksresults",
    "create": "taskscreate",
    "assigntask": "", //review
    "toc-landing": "findingresources",
    "resource": "resourcelibrary",
    "assign": "taskscreate",
    "dashboard": "",
    "edit": "taskscreate"
};

export const STUDENT_HOME_HELP_SECTION_MAP = {
    "homepage": "studenthomepage",
    "assignments": "studentmytasks",
    "assignmentsummary" : "studentmytasks",
    "assessment" : "studentmytasks",
    "lesson" : "studentmytasks",
    "nodeid" : "studentmyresults"
}

export const INSIGHTS_HELP_SECTION_MAP = {
    "dashboard" : "insightsdashboard",
    "groups" : "groupinsights",
    "students": "studentinsights",
    "assessments": "assessmentinsights",
    "task-insights": "taskinsights",
    "studentshare": "sharingresults"
} 
/**maps end */
