import React from 'react';
import ScriptCache from './script-cache';
import { IFrame } from '../iframe/IFrame';
import { PageNotFound, CommonUtils } from 'common-components-spa';

class MicroFrontend extends React.Component {
    scriptCache;
    abortController;

    constructor(props) {
        super(props);
        this.state = {
            containerId: '',
            host: '',
            fallbackToIFrame: false,
        };
        this.scriptCache = new ScriptCache();
        this.abortController = new AbortController();
    }

    componentDidMount() {
        this.setState({
            containerId: this.props.containerId,
            host: this.props.host
        }, () => {
            this.loadAssets();
        });
    }

    /**
     * Cache the asset manifest in session storage to prevent excessive calls
     * @param spa
     * @returns {Promise<unknown>}
     */
    fetchAssetManifest(spa) {
        return new Promise((resolve, reject) => {
            let cachedManifest = JSON.parse(sessionStorage.getItem(`asset-manifest-${spa}`));
            console.log('Cached', cachedManifest);
            if (!cachedManifest) {
                this.abortController = new AbortController();
                return fetch(`${spa}/asset-manifest.json`, {
                    signal: this.abortController.signal
                }).then(res => res.json()).then(manifest => {
                    cachedManifest = manifest;
                    sessionStorage.setItem(`asset-manifest-${spa}`, JSON.stringify(cachedManifest));
                    resolve(cachedManifest);
                }).catch(error => reject(error));
            } else {
                resolve(cachedManifest);
            }
        })
    }

    /**
     * Cache the manifest in session storage to prevent excessive calls
     * @param spa
     * @returns {Promise<unknown>}
     */
    fetchManifest(spa) {
        return new Promise((resolve, reject) => {
            let cachedManifest = JSON.parse(sessionStorage.getItem(`manifest-${spa}`));
            if (!cachedManifest) {
                this.abortController = new AbortController();
                return fetch(`${spa}/manifest.json`, {
                    signal: this.abortController.signal
                }).then(res => res.json()).then(manifest => {
                    cachedManifest = manifest;
                    sessionStorage.setItem(`manifest-${spa}`, JSON.stringify(cachedManifest));
                    resolve(cachedManifest);
                }).catch(error => reject(error));
            } else {
                resolve(cachedManifest);
            }
        })
    }

    loadAssets() {
        const { errorInterceptor, /*setIframeLevelErrorPage*/ } = this.props;
        const { containerId, host } = this.state;
        console.log('Mount MF', containerId);

        console.log(`${host}/asset-manifest.json`);
        this.fetchAssetManifest(host)
            .then(manifest => {
                const entryPoints = manifest['entrypoints'];
                let styles = [];
                let scripts = [];
                entryPoints.forEach(path => {
                    path.includes('.css') ? styles.push(`${host}/${path}`) : scripts.push(`${host}/${path}`);
                });
                console.log(scripts);
                console.log(styles);
                return { styles, scripts };
            })
            .then(({ scripts, styles }) => {
                styles.forEach(style => {
                    this.loadStyles(style);
                });
                return this.scriptCache
                    .setScripts(scripts)
                    .setContainerId(containerId)
                    .load(scripts);
            })
            .then(event => {
                console.log('Scripts Loaded', event);
                if (this.props.host === host)
                    this.renderMicroFrontend();
            })
            .catch((error) => {
                console.log('fetch error', { error });
                // if (error.name !== 'AbortError') {
                if (CommonUtils.isValidUrl(host)) {
                    this.setState({ fallbackToIFrame: true });
                } else {
                    // setIframeLevelErrorPage(<PageNotFound/>)
                }
                // }

            });
    }

    loadNewAssets(prevProps) {
        console.log('Switch Container', prevProps.containerId, this.props.containerId);
        this.unmountMicroFrontend(prevProps.host, prevProps.containerId, () => {
            console.log('Unmounted', prevProps.host);
            this.setState({ fallbackToIFrame: false });
            this.setState({ containerId: this.props.containerId, host: this.props.host }, () => {
                console.log('Mounted', this.state.host);
                this.loadAssets();
            });
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.containerId !== this.props.containerId) {
            console.log('Props updated', prevProps, this.props);
            // we are trying to unmount previous app
            if (this.abortController) {
                console.log('Abort and load assets');
                // Add a listener to abort event.
                this.abortController.signal.addEventListener('abort', (event) => {
                    console.log('Abort Signal', event);
                });
                // Abort on-going requests and load new assets.
                this.abortController.abort();
            }
            this.loadNewAssets(prevProps);
        }
    }

    loadStyles = (style, containerId, index) => {
        const styleId = `style-${containerId}${index}`;
        if (!document.getElementById(styleId)) {
            const styleRef = document.createElement('link');
            styleRef.setAttribute('rel', 'stylesheet');
            styleRef.setAttribute('type', 'text/css');
            styleRef.setAttribute('href', style);
            document.head.appendChild(styleRef);
        }
    };

    componentWillUnmount() {
        console.log("componentWillUnmount micro-frontend")
        this.unmountMicroFrontend(this.state.host, this.state.containerId)
    }

    unmountMicroFrontend = (spa, containerId, callback = () => { }) => {
        let cachedManifest = JSON.parse(sessionStorage.getItem(`manifest-${spa}`));
        const name = cachedManifest && cachedManifest['name'];
        if (window[`unmount${name}`]) {
            window[`unmount${name}`](containerId);
        }
        callback();
    };

    renderMicroFrontend = () => {
        const { window, /*setIframeLevelErrorPage*/ } = this.props;
        const { containerId, host } = this.state;
        this.fetchManifest(host).then(res => {
            console.log('Manifest', res);
            const spaName = res['name'];
            if (window[`render${spaName}`]) {
                window[`render${spaName}`](containerId);
            }
        }).catch(error => {
            console.log('Error fetching Manifest. Falling back to iFrame', { error });
            if (CommonUtils.isValidUrl(host)) {
                this.setState({ fallbackToIFrame: true });
            } else {
                // setIframeLevelErrorPage(<PageNotFound/>)
            }
        });
    };

    render() {
        const { host } = this.props;
        const { fallbackToIFrame, containerId } = this.state;

        if (fallbackToIFrame) {
            return <IFrame src={host} />;
        }

        return <div id={containerId} />;
    }
}

MicroFrontend.defaultProps = {
    document,
    window,
};

export default MicroFrontend;
