import React from 'react';

export function UnAuthorizedUserAlert() {
  return (
    <div class="p-5 m-5 d-flex flex-column justify-content-center align-items-center deactivated-user">
        <div class="d-flex align-item-center justify-content-center accdeactivate">
            <div>
                <img src={`${process.env.PUBLIC_URL}/images/user-deactivated.svg`} alt="Account Deactivated" />
            </div>
            <div class="rightcnt">
                <p class="fontModel-hc pl-4">Unauthorised</p>
                <p class="ml-4 mdlcnt">
                    You are not allowed to view this page.
                </p>
            </div>
        </div>
    </div>
  )
}
