import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "common-components-spa/dist/index.min.css"
import './index.css';
import AppWrapper from './AppWrapper';
import { HashRouter } from 'react-router-dom';
import { routerBasePath } from './constants/Config';
import { ScrollToTop } from './utils/CustomHooks';

const setBaseUrl = (baseUrl, proxyBaseUrl) => {
  if (!sessionStorage.getItem("baseUrl")) {
    sessionStorage.setItem("baseUrl", baseUrl);
  }
  if (!sessionStorage.getItem("proxyBaseUrl")) {
    sessionStorage.setItem("proxyBaseUrl", proxyBaseUrl);
  }

}
let value = false;
const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

value = params.mode;
(async function setSession() {
  if (process.env.NODE_ENV != 'development') {
    let environment = ['dev', 'qa', 'stg', 'perf']
    let gethostname = window.location.hostname.split(".")[0];
    let domainBasedUrl = environment.includes(gethostname) ? `https://${gethostname}.pet.ift.pearson-intl.com` : 'https://activehub.pearson.com';
    let basedUrl = environment.includes(gethostname) ? `https://${gethostname}.gps.ift.pearson-intl.com` : 'https://gps.ift.pearson-intl.com';
    await setBaseUrl(basedUrl, domainBasedUrl)
  }
    ReactDOM.render((
      <HashRouter basename={routerBasePath}>
        <ScrollToTop />
        <AppWrapper />
      </HashRouter>
    ), document.getElementById('root'));
})();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
