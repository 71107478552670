import React, {useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useLocation, useRouteMatch} from 'react-router-dom';

import './IFrame.scss';
//import {startWith} from "rxjs/operators";

// function getPathWithParams(iframe) {
//   const iframeWindow = iframe.contentWindow;
//   const iframeLocation = iframeWindow.location;
//   return iframeLocation.pathname + iframeLocation.search;
// }

// important info
// src: relative path with leading / and no trailing /
// we remove trailing / if it exists
//
// deeplink: has leading /. doesn't matter if it has trailing /
//
// routerBasePath: no terminating /
// if not empty, should have leading /
//
// fullurl: full pathname of the url
// router guarantees that it will have leading /. doesn't matter if it has trailing /
//
// routeUrl: pathname till the router match
// router guarantees that it will have leading /. Router guarantees it won't have trailing /


// TODO: 
// message handling b/w wrapper and iframe content.
export function IFrame(props) {
  // we will have a bug if one url1.startsWith(url2) is true
  // where url1 and url2 are valid spa urls
  // src will never have trailing /
  let {src} = props;
  if (src.endsWith('/')) {
    src = src.slice(0, src.length - 1);
  }

  //const history = useHistory();
  const {url: routeUrl} = useRouteMatch();
  // this gives us the query params in string form
  const fullUrl = useLocation().pathname;

  // deep link always starts with /
  const deeplink = useMemo(() => {
    // parse the url to get deep link
    let fullBase = `${routeUrl}`;
    if (!fullUrl.startsWith(fullBase)) return '';
    return fullUrl.slice(fullBase.length);
  }, [fullUrl, routeUrl]);

  const iframeRef = useRef();
  // TODO: 
  // to show some loader ui covering iframe to hide glitches
  //const [iframeLoading, setIframeLoading] = useState(false);
  // TODO: 
  // to show fallback ui with retry mechanism if content fails to load
  // event handling is peiding for this
  //const [iframeError, setIframeError] = useState(false);
  // this tracks the actual src inside iframe
  // and not just the src attribute sent to iframe
  // these will be different when src is set from within iframe
  const [actualSrc, setActualSrc] = useState(null);

  // util method to handle iframe height management
  // const handleContentBodyHeight = useCallback(() => {
  //   const iframe = iframeRef.current;
  //   if (!iframe) return;
  //   if (!iframe.src || iframe.src === 'about:blank') {
  //     iframe.height = undefined;
  //     return;
  //   }
  //   iframe.height = undefined;
  //   iframe.height = iframe.contentDocument.body.scrollHeight;
  // }, []);

  // const mutationObserver = useMemo(() => {
  //   return new MutationObserver(() => {
  //     handleContentBodyHeight();
  //   });
  // }, [handleContentBodyHeight]);

  // const observeDomUpdates = useCallback(() => {
  //   const iframe = iframeRef.current;
  //   if (!iframe) return;
  //   const iframeWindow = iframe.contentWindow;
  //   mutationObserver.observe(iframeWindow.document.documentElement, {
  //     attributes: false,
  //     characterData: true,
  //     childList: true,
  //     subtree: true
  //   });
  // }, [mutationObserver]);

  // const disconnectDomUpdates = useCallback(() => {
  //   mutationObserver.disconnect();
  // }, [mutationObserver]);

  // this is used to set change iframe url when deeplinking
  useEffect(() => {
    let urlToBeUsed = src;
    if (deeplink) {
      urlToBeUsed = `${src}${deeplink}`;
    }

    const iframe = iframeRef.current;
    if (!iframe) return;
    if (actualSrc !== urlToBeUsed) {
      setActualSrc(src);
      //disconnectDomUpdates();
    }
    // eslint disable is not a hack
    // we need to run this only when src or deep link changes
    // as they are the only things that can be set from external sources
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, deeplink]);

  useEffect(()=>{
    const iframe = iframeRef.current;
    if (!iframe) return;

    if (actualSrc) {
      // iframe height will be calculate again once it loads
      iframe.height = undefined;
      //setIframeLoading(true);
      //setIframeError(false);
      iframe.src = actualSrc;
    } else {
      //setIframeLoading(false);
      //setIframeError(false);
      // iframe with null or empty url loads same page again
      // need to handle this properly
      iframe.src = 'about:blank';
    }
  }, [actualSrc]);

  // useEffect(() => {
  //   window.addEventListener('resize', handleContentBodyHeight);
  //   return () => window.removeEventListener('resize', handleContentBodyHeight);
  // }, [handleContentBodyHeight]);

  // this will be called when iframe changes url from within
  // const onLocationChange = useCallback((url) => {
  //   setActualSrc(url);
  //   if (url.startsWith(src)) {
  //     history.replace(`${routeUrl}${url.slice(src.length)}`);
  //   }
  //   // dont need to run if history object changes
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [routeUrl, src]);

  // this code is to track history changes in the iframe
  // useEffect(() => {
  //   const iframe = iframeRef.current;
  //   if (!iframeRef.current) return;

  //   const iframeWindow = iframe.contentWindow;
  //   const iframeHistory = iframeWindow.history;
  //   const iframeDocument = iframe.contentDocument;
  //   iframeDocument.body.style.overflow = "hidden";

  //   const originalPushState = iframeHistory.pushState;
  //   const originalReplaceState = iframeHistory.replaceState;

  //   const listener = function () {
  //     onLocationChange(
  //       getPathWithParams(iframe)
  //     );
  //   };

  //   iframeHistory.pushState = function () {
  //     originalPushState.apply(iframeWindow.history, arguments);
  //     listener();
  //   };

  //   iframeHistory.replaceState = function () {
  //     originalReplaceState.apply(iframeWindow.history, arguments);
  //     listener();
  //   };

  //   iframeWindow.addEventListener('popstate', listener);

  //   // make sure to cleanup stuff
  //   return () => {
  //     iframeWindow.removeEventListener('popstate', listener);
  //     iframeHistory.replaceState = originalReplaceState;
  //     iframeHistory.pushState = originalPushState;
  //   }

  //   // we are adding actualSrc and src and iframeLoading as extra depencies because
  //   // window object will change when this happens
  // }, [onLocationChange, actualSrc, src, iframeLoading]);

  // const onLoad = useCallback(() => {
  //   setIframeLoading(false);
  //   setIframeError(false);
  //   // set iframe height when content loads
  //   // TODO: 
  //   // need to figure out how to set height when content height changes from within
  //   // not working without timeout.
  //  // observeDomUpdates();
  //   setTimeout(() => {
  //     handleContentBodyHeight();
  //   }, 0);
  // }, [handleContentBodyHeight, 
  //   //observeDomUpdates
  // ]);

  return (
    <iframe
      ref={iframeRef}
      className="gwIframe"
      title="spa container"
      //onLoad={onLoad}
      allow="fullscreen"
    />
  );
}

IFrame.propTypes = {
  src: PropTypes.string
};
