import React, { Fragment } from 'react';

export default function Maintenance(props) {

    return (
        <Fragment>
            <style>
                .gwHeader, .gwFooter {`{ display: none}`}
            </style>
        <div style={{ 'text-align': 'center', 'padding': '0', 'font-family': 'Open Sans' }}>
            <img style={{ 'margin': '2% 0%' }} src="/activehub/activehub-logo.png" />
            <h1 style={{ 'font-size': '50px', 'font-weight': '100', 'text-align': 'center' }}>We&rsquo;ll be back soon!</h1>
            <div style={{ 'font-family': 'Open Sans', 'font-weight': '100', 'font-size': '20px', 'color': '#000', 'text-align': 'center', 'display': '-webkit-box', 'display': '-ms-flexbox', 'display': 'flex', '-webkit-box-pack': 'center', '-ms-flex-pack': 'center', 'justify-content': 'center', '-webkit-box-align': 'center', '-ms-flex-align': 'center', 'align-items': 'center', 'margin-top': '20px' }}>
                <p>Sorry for the inconvenience. <br /></p>
            </div>
            <div style={{ 'font-family': 'Open Sans', 'font-weight': '100', 'font-size': '20px', 'color': '#000', 'text-align': 'center', 'display': '-webkit-box', 'display': '-ms-flexbox', 'display': 'flex', '-webkit-box-pack': 'center', '-ms-flex-pack': 'center', 'justify-content': 'center', '-webkit-box-align': 'center', '-ms-flex-align': 'center', 'align-items': 'center' }}>
                <p>We&rsquo;re performing some maintenance at the moment.</p>
            </div>
            <div style={{ 'font-family': 'Open Sans', 'font-weight': '100', 'font-size': '20px', 'color': '#000', 'text-align': 'center', 'display': '-webkit-box', 'display': '-ms-flexbox', 'display': 'flex', '-webkit-box-pack': 'center', '-ms-flex-pack': 'center', 'justify-content': 'center', '-webkit-box-align': 'center', '-ms-flex-align': 'center', 'align-items': 'center', 'margin-top': '10px' }}>
                <p>&mdash; The ActiveHub Team</p>
            </div>

        </div>
        </Fragment>
    )
}