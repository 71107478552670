import React from 'react'
import Skeleton from "react-loading-skeleton";
import './CollapsibleSideBarMenu.scss'

export function CollapsibleSideBarMenuLoader() {
  return (
    <aside id='sidebar-container' className={`d-block side-nav`}>
      <div className="ml-2">
        <Skeleton width={220} height={20} />
        <Skeleton width={220} height={20} />
      </div>
    </aside>
  )
}
