import React from "react";

import "./gridcard.scss";

const GridCard = ({ nav, changeApplication }) => {
  const tagLine = {
    admin: "Manage users and groups.",
    assessments: "Assessment library, assessment builder and mark entry.",
    helphub: "Tips and tutorials on using ActiveHub.",
    insights: "Performance analysis for groups, students, assessments and tasks.",
    "lesson-plan": "A library of resources to support teaching, practice and intervention.",
  };

  /**
   * handle click handler of spa
   */
  const onClickHandler = () => {
    if (nav.link instanceof Function) {
      /**
       * if link type is of function that mean we have to
       * generate the admin link and redirect to admin
       */
      nav.link();
    } else if (nav?.externalLink) {
      /**
       * if external link set then redirect to the set URL
       */
      window.open(nav?.externalLink);
    } else {
      changeApplication(nav.spaId, nav.name);
    }
  };

  return (
    <div className="dashboard-gridcard" onClick={onClickHandler}>
      <div className="divider"></div>
      <img
        src={`${process.env.PUBLIC_URL}/images/${nav.description}-dashboard-icon.svg`}
        alt={`${nav.name} icon`}
      />
      <h2 className="title">{nav.name}</h2>
      <p>{tagLine[nav.description]}</p>
    </div>
  );
};

export default GridCard;
