/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useContext } from "react";
import PropTypes from 'prop-types';
import { useOutsideClick } from '../../utils/CustomHooks';
import { AppContext } from "../../App";
import { Can } from 'common-components-spa'

export function ProfileDropDown(props) {
  const dropDownRef = useRef();
  const appContext = useContext(AppContext);
  useOutsideClick(dropDownRef, () => {
    if (props.isOpen) {
      props.showDropDown(false);
    }
  });
  const {
    headerDetails,
    footerDetails,
    iesSession,
    userName,
    defaultSpaId,
    selectedSpaId,
    setSelectedSpaId,
    // activeHubInfo,
    translate: t,
    wrapperLevelErrorPage,
  } = appContext;
  //if (!props.isOpen) return null;
  return (
    <ul
      className={`gwProfile__dropcontent ${props.isOpen ? `gwProfiledrop__visible` : `gwProfile__hidden`}`} ref={dropDownRef} id="user_menu">
      {
        props.accountDropdownOptions.map(item => (
          <li key={item.label}>
            <a
              href="#"
              tabIndex={props.isOpen ? "0" : "-1"}
              key={item.label}
              className={`gwProfile__dropcontent__list ${item.label?.toLowerCase() === "logout" ? `no-caps` : ``}`}
              data-target="#logout-popup"
              data-toggle="modal"
              onKeyUp={(e)=>{if(e.key==="Enter") item.action()}}
              onClick={item.action}>
              {item.label?.toLowerCase() === "logout" ? "Sign out" : item.label}
            </a>
          </li>
        ))}
    </ul >
  )
}

ProfileDropDown.propTypes = {
  accountDropdownOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired
  })).isRequired,
  isOpen: PropTypes.bool,
  showDropDown: PropTypes.func.isRequired
}
