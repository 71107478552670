import { SpaWrapper } from "../page/spaWrapper/SpaWrapper";

const Routes = [
  {
    component: SpaWrapper,
    path: "/application/:applicationId?",
    exact: false,
    title: "Everest Wrapper",
  }
];

export default Routes;
