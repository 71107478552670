import React, { useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Button } from 'common-components-spa'
import './LogOutPopUp.scss'
import { AppContext } from '../../App'

export function LogOutPopUp(props) {
    const {translate:t} = useContext(AppContext)
    return (
        <Modal dialogClassName="modal__dialog" show={props.show} className="gwLogoutPopup" onHide={props.close} centered >
            <Modal.Header className="modal__header gwLogoutPopup__header" closeButton>
                <Modal.Title><h2 className="logout-popup-title">Sign Out</h2></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal__body gwLogoutPopup__body">
                <img className="gwLogoutPopup__body--img" src={`${process.env.PUBLIC_URL}/images/bg-logout.png`} alt={"User logout"} />
                <p className="py-3 gwLogoutPopup__body--text">Are you sure you want to sign out?</p>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center modal__footer gwLogoutPopup__footer">
                <Button className="button__bordered gwButton--cancel modalbtn" value={"Cancel"} data-dismiss="modal" tabIndex="" onClick={props.close} />
                <Button className="ml-3 button__primary modalbtn" value={"Sign Out"} data-dismiss="modal" tabIndex="" onClick={props.handleLogout} />

            </Modal.Footer>
        </Modal>
    );
};