import { getAppcuesConfig } from '../network/ApiService'
import { useNetworkResponse } from 'common-components-spa';
import { useEffect } from 'react';
import ScriptCache from '../components/micro-frontend/script-cache';
export function useAppcues() {
    const appcuesConfigResponse = useNetworkResponse();
    const fetchAppcuesConfig = () => {
        appcuesConfigResponse.dispatch({ type: "STARTED" });
        const subscription = getAppcuesConfig().subscribe(
            (response) => {
                let newResponse = {"ENV":"LOCAL","SCRIPT_URL":"//fast.appcues.com/92609.js","ALLOWED_ORIGIN":[window.location.hostname]};
                appcuesConfigResponse.dispatch({ type: "SUCCESS", payload: newResponse });
                const scriptCache = new ScriptCache();
                const scriptUrls = [newResponse.SCRIPT_URL]
                const isAllowed = newResponse.ALLOWED_ORIGIN.includes(window.location.hostname);
                if (!isAllowed) return;
                scriptCache.setScripts(scriptUrls)
                    .setContainerId("appcues-scipt")
                    .load(scriptUrls).then(() => {
                        console.log('Appcues success')
                    });
            },
            (error) => {
                console.log('error fetching Appcues config')
            }
        );
    }

    useEffect(() => {
        fetchAppcuesConfig();
    }, [])

    return {
        appcuesData: appcuesConfigResponse.data
    }
}  