import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { AppContext } from "../../App";
import { useHistory } from 'react-router-dom';
import { routerBasePath } from '../../constants/Config';


const contentLabelPaddingLeft = 17;

const useStyles = makeStyles({
    title: {
        fontWeight: 500,
        fontSize: "1.1rem",
        color: "black",
        paddingLeft: "0.5rem",
        verticalAlign: "middle"
    },
    closeIcon: {
        padding: "0px"
    },
    iconContainer: {
        display: "none"
    },
    titleContainer: {
        color: "#007fa3",
        padding: "1.1rem 0.5rem",
        cursor: "pointer",
        borderBottom: "1px solid #f5f5f5"
    },
    item: {
        padding: "0.5rem 0rem"
    },
    rootSelected: {
        padding: "0.5rem 0rem",
        fontWeight: "600"
    },
    group: {
        marginLeft: "0px"
    },
    content: {
        paddingLeft: `${contentLabelPaddingLeft}px`
    },
    subContent: {
        paddingLeft: `${contentLabelPaddingLeft * 2}px`
    },
    label: {
        background: "transparent !important",
        fontWeight: "inherit"
    },
    childRootSelected: {
        borderLeft: "3px solid #9e007e!important",
        fontWeight: "600"
    },
    childGroup: {
        fontWeight: "initial"
    }
});


export default function MobileMenu(props) {
    const classes = useStyles();
    const history = useHistory();
    const { entities, isOnlyStudentHomeSpa, setSelectedSpaId } = useContext(AppContext);

    const clickHandler = (event, path, spaId) => {
        event.preventDefault();
        history.push(`${routerBasePath}/application/${encodeURIComponent(spaId)}${path}`)
        props.setMobileOpen(false)
    }

    const spaClickHandler = (ev, spaId, description) => {
        if (props.selectedSpaId === spaId) return
        props.setSelectedSpaId(spaId)
        if (!props.spaRoutes?.[description]) {
            props.setMobileOpen(false)
        }
    }

    const getSubMenus = (parentNavItem) => {
        return <TreeView
            classes={{ root: classes.childGroup }}>
            {props.spaRoutes?.[parentNavItem.description]?.map((sub, index) => (
                <TreeItem
                    key={`${parentNavItem.spaId}_${index}`}
                    nodeId={`${index}`}
                    label={sub.label}
                    classes={{ iconContainer: classes.iconContainer, content: classes.subContent, label: classes.label, root: props.activePath === sub.path ? classes.childRootSelected : null }}
                    onLabelClick={(ev) => clickHandler(ev, sub.path, parentNavItem.spaId)}
                />
            ))}
        </TreeView>
    }

    const handelHomeMenuClick = () => {
        props.onClose();
        setSelectedSpaId('dashboard');
    }

    return (<div className={classes.root}>
        <div className={classes.titleContainer} >
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={props.onClose}
                className={classes.closeIcon}
            >
                <CloseIcon />
            </IconButton>
            <span className="screen-reader-only">opens the to visualize the menu text</span>
            <span className={classes.title}>Menu</span>
        </div>
        {isOnlyStudentHomeSpa ? getSubMenus(props.navItems?.[0]) :
            (<TreeView defaultExpanded={['dashboard']}>
                 <TreeItem
                    className={`mobileDashboardLink ${props.selectedSpaId === 'dashboard' && 'active'}`}
                    key={"dashboard"}
                    nodeId={"dashboard"}
                    label={"Home"}
                    classes={{ root: props.selectedSpaId === 'dashboard' && classes.rootSelected}}
                    onLabelClick={handelHomeMenuClick}
                >
                </TreeItem>
                {props.navItems?.map((item) => (
                    <TreeItem
                        key={item.spaId}
                        nodeId={item.spaId}
                        label={entities.decode(item.name)}
                        classes={{ iconContainer: classes.iconContainer, root: props.selectedSpaId === item.spaId ? classes.rootSelected : classes.item, expanded: classes.rootSelected, group: classes.group, content: classes.content, label: classes.label }}
                        onLabelClick={(ev) => spaClickHandler(ev, item.spaId, item.description)}
                    >
                        {getSubMenus(item)}
                    </TreeItem>
                ))
                }
                <TreeItem
                    className='mobileAdminLink'
                    key={"admin"}
                    nodeId={"admin-link"}
                    label={"Admin"}
                    onLabelClick={(e) => props.adminLinkClickHandler(e)}
                >
                </TreeItem>
            </TreeView>)}
    </div>);
}
