import React, { useRef, useState, useContext } from "react";
import { AppContext } from "../../App";
import "./NotificationPopup.scss";
import { Button, Loader, useOutsideClick } from "common-components-spa";
import { forkJoin } from "rxjs";
import { readNotification } from "../../network/ApiService";

const HTMLEntities = require('html-entities');

const Entities = ('AllHtmlEntities' in HTMLEntities) ? HTMLEntities.AllHtmlEntities : function() {
  this.encode = HTMLEntities.encode;
  this.decode = HTMLEntities.decode;
  this.decodeEntity = HTMLEntities.decodeEntity;

  return this;
}
const entities = new Entities();
const NotificationPopup = ({ isOpen, onClose, customDataAttributes }) => {
    const { notifications, setNotifications, setSelectedSpaId } = useContext(AppContext);
    const [showMoreObj, setShowMoreObj] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const popUpRef = useRef();

    useOutsideClick(popUpRef, () => {
        onClose && onClose()
    })

    const handleClearAll = () => {
        readNotifications(notifications);
        setNotifications([]);
    }

    const removeNotification = (ele) => {
        const notificationCopy = [...notifications];
        const eleIndex = notifications.findIndex(element => element["notification-urn"] === ele["notification-urn"]);
        if (eleIndex >= 0) {
            notificationCopy.splice(eleIndex, 1);
            setNotifications(notificationCopy);
        }
    }

    const onClickDelete = () => {
        const obj = { ...showMoreObj };
        removeNotification(obj);
        readNotifications([obj])
        setShowMoreObj(null);
    }

    const renderMoreOption = () => {
        return <div className={`userDropitems--v2 show deldro`}>
            <div className="userDropitems--v2_frmgrp">
                <div className="userDropitems--v2_frmgrp__item">
                    <Button useButtonClass={false} tabIndex={isOpen ? 0 : -1} className="link" onClick={onClickDelete}>
                        {"Delete"}
                    </Button>
                </div>
            </div>
        </div>
    }

    const readNotifications = (notificationData, successCallback) => {
        if (!notificationData.length) {
            return
        }
        setIsLoading(true)
        forkJoin(notificationData.map(ele => (readNotification(ele["notification-urn"])))).subscribe((response) => {
            successCallback && successCallback();
            setIsLoading(false)
        }, error => {
            setIsLoading(false)
            console.log("error", error)
        })
    }

    const onClickBody = (ele) => {
        if (ele.url) {
            removeNotification(ele);
            readNotifications([ele]);
            var evt = new CustomEvent("OpenNotification", { detail: entitiesDecode(ele.url) });
            window.dispatchEvent(evt);
            onClose && onClose();
        }
    }

    const entitiesDecode = (string) => {
        try {
            return decodeURIComponent(entities.decode(entities.decode(entities.decode(entities.decode(string)))))
        } catch {
            return entities.decode(entities.decode(entities.decode(entities.decode(string))))
        }
    }

    const getNotificationBody = (item) => {
        let string = item['notification-body']
        string = string.replace(`[<a href="${item.url}">`, "")
        string = string.replace(`</a>]`, "")
        return entitiesDecode(string);
    }

    return <div ref={popUpRef} className="notification-popup" role="menu" {...customDataAttributes}>
        {/* {isLoading && <Loader />} */}
        <div className={`Table-Userblk Table-Userblk--head ${isOpen ? "show" : "hide"}`}>
            <div className="Table-Userblk__head">
                <div className="d-flex justify-content-between align-items-center">
                    <h4 className="Table-Userblk__head__name mb-0">Notifications</h4>
                    <button role="menuitem" className={"notification-close"} onClick={onClose} tabIndex={isOpen ? 0 : -1} ariaDescribeBy={"close notifictions popup"}>
                        <i className="material-icons Table-Userblk__close p-0 align-middle">close</i>
                    </button>
                </div>
            </div>
            {notifications.length > 0 ?
                <div className="Table-Userblk__body">
                    <div className="d-flex justify-content-end mb-2">
                        <Button className="button__v2 button__v2__primary__bordered clearall" tabIndex={isOpen ? 0 : -1}
                            onClick={handleClearAll}
                        >Clear all
                        </Button>
                    </div>
                    <div className="Table-Userblk__nothead">
                        {notifications.map((item, index) => (
                            <div className="d-flex align-items-start mb-3 border-bottom" key={index}>
                                <div className="Table-Userblk__profileinfo">
                                    <Button useButtonClass={false} tabIndex={isOpen ? 0 : -1} className="link" onClick={() => { onClickBody(item) }} role="menuitem"
                                    >
                                        {getNotificationBody(item)}
                                    </Button>
                                </div>
                                <div className="position-relative">
                                    <Button
                                        className="button__v2 m-0"
                                        tabIndex={isOpen ? 0 : -1}
                                        onClick={() => setShowMoreObj(showMoreObj !== null ? null : item)}
                                    >
                                        <img src={`${process.env.PUBLIC_URL}/images/more.svg`}
                                            className="Table-Userblk__handimg" alt={`More options icon`} />
                                    </Button>
                                    {showMoreObj?.["notification-urn"] === item["notification-urn"] && renderMoreOption()}
                                </div>
                            </div>
                        ))
                        }
                    </div>
                    {/* <div className="d-flex align-items-center justify-content-center">
                        <Button className="button__v2 p-0 viewmore"
                            onClick={onViewMore}
                        >View More </Button>
                    </div> */}
                </div> : <span className="text-center d-block p-3">
                    No new notifications
                </span>}
        </div>
    </div>
}

export default NotificationPopup;