import React from 'react';
import { useHistory } from 'react-router-dom';
import { routerBasePath } from '../../constants/Config';
import './Menu.scss'

export default function SpaMenu(props) {
    const history = useHistory();
    const selectedId = document.activeElement.id

    const clickHandler = (event) => {
        event.preventDefault();
        props.setActivePath(event.currentTarget.dataset["path"])
        history.push(`${routerBasePath}/application/${encodeURIComponent(props.selectedSpaId)}${event.currentTarget.dataset["path"]}`)
    }

    return (
        <div id="routes-container">
            {
                props.selectedSpaRoutes.map((item) => (
                    <button role="tab" id={item?.id} aria-selected={item?.id === selectedId ? true : false} onClick={clickHandler} data-path={item.path}
                        className={`ogaNav__lst__grp__lnk ${item?.path === props.activePath ? `active__activehub` : `activehub`} ${item.isStudentMenu ? "student-menu" : ""}`}>
                        <span
                            className={`ogaNav__lst__grp__lnk__txt ${item.isStudentMenu ? "student-menu-item" : ""} `}>{props.t(item.label)}</span>
                    </button>
                ))
            }
        </div>
    )
}
