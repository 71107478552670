import React from 'react';
import PropTypes from 'prop-types';
import './Footer.scss';
import { CommonUtils } from 'common-components-spa';
export function Footer(props) {

    function handleAccessibilityClick() {
        if (!props.isAccesibilityPopupOpen) {
            props.toggleAccessibilityDialog(true);
        }

    }

    return (
        <footer className="gwFooter">
            <div className="container-fluid">
                <div className="position-relative d-md-flex">
                    <div className="gwFooter__links">
                        <ul className="gwFooter__links__ul">
                            { props.footerLinks.map((footer, index) => {
                                    return (
                                        <li className="gwFooter__links__li" key={index}>
                                            <a
                                                tabIndex=""
                                                title={footer.description}
                                                onClick={() => {
                                                    CommonUtils.launchWindowByURL(footer.launchUrl, footer.launchType);
                                                }}
                                                aria-describedby={`launchWindowByURL_${footer.footerlinkId}`}
                                                tabindex="0"
                                            >
                                                {footer.name}
                                            </a>
                                            <p id={`launchWindowByURL_${footer.footerlinkId}`} aria-hidden="true" style={{ display: "none" }}>
                                                opens in a new tab
                                            </p>
                                        </li>
                                    )
                                })
                            }
                            <li className="gwFooter__links__li"><span>Copyright © {new Date().getFullYear()} Pearson All rights reserved.</span></li>
                        </ul>
                    </div>
                    {/* <div className="gwFooter__copy">© Pearson Education Ltd 2020</div> */}                    
                </div>
            </div>
        </footer>
    )
}

Footer.propTypes = {
    footerLinks: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string,
            footerlinkId: PropTypes.string,
            launchType: PropTypes.string,
            launchUrl: PropTypes.string,
            location: PropTypes.string,
            name: PropTypes.string
        })
    ).isRequired,
    copyRight: PropTypes.string,
    toggleAccessibilityDialog: PropTypes.func.isRequired,
    isAccesibilityPopupOpen: PropTypes.bool.isRequired
};
