import React from 'react';

export function DeactivatedUserAlert() {
  return (
    <div class="p-5 m-5 d-flex flex-column justify-content-center align-items-center deactivated-user">
        <div class="d-flex align-item-center justify-content-center accdeactivate">
            <div>
                <img src={`${process.env.PUBLIC_URL}/images/user-deactivated.svg`} alt="Account Deactivated" />
            </div>
            <div class="rightcnt">
                <p class="fontModel-hc pl-4">Account Deactivated</p>
                <p class="ml-4 mdlcnt">
                    Unfortunately, your Pearson Gateway account has been deactivated. If you need to reactivate your account please <span class="font-italic"><strong>contact your school administrator.</strong></span>
                </p>
            </div>
        </div>
    </div>
  )
}
